import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163')
];

export const server_loads = [0,2,3,14,15,16,17,18,19,6,9,10,11,12,13,8];

export const dictionary = {
		"/(landing)": [~20,[2]],
		"/auth/error": [117],
		"/(legal)/cookies": [21,[3]],
		"/(public)/dana": [~24,[4]],
		"/(public)/directorio-info": [27,[4]],
		"/(public)/directorio": [~25,[4]],
		"/(public)/directorio/patient-form": [~26,[4]],
		"/error": [118],
		"/error/no-js": [119],
		"/(public)/links": [~28,[4]],
		"/(public)/login": [~29,[4]],
		"/(public)/logout": [30,[4]],
		"/onboarding/clinica": [~128,[14]],
		"/onboarding/new-team-page": [~130,[14]],
		"/onboarding/new-team": [~129,[14]],
		"/onboarding/paciente": [~131,[14]],
		"/onboarding/[teamId]/completo": [~124,[14]],
		"/onboarding/[teamId]/locations": [~125,[14]],
		"/onboarding/[teamId]/owner": [~126,[14]],
		"/onboarding/[teamId]/plan": [~127,[14]],
		"/onboarding/[teamId]/[professionalId]": [~120,[14]],
		"/onboarding/[teamId]/[professionalId]/end": [~121,[14]],
		"/onboarding/[teamId]/[professionalId]/invoicing": [~122,[14]],
		"/onboarding/[teamId]/[professionalId]/key": [~123,[14]],
		"/paciente": [~132,[15]],
		"/paciente/[patientId]/documents/[docId]": [~133,[15]],
		"/paciente/[patientId]/settings": [~134,[15]],
		"/paddle/pay": [135],
		"/(legal)/politica-privacidad": [22,[3]],
		"/(public)/redirect-home": [~31,[4]],
		"/(public)/registro": [~32,[5]],
		"/(public)/registro/clinica": [~33,[5]],
		"/(public)/registro/directorio": [~34,[5]],
		"/(public)/registro/paciente": [~35,[5]],
		"/(public)/registro/paciente/[inviteCode]": [~36,[5]],
		"/(public)/registro/paciente/[inviteCode]/completo": [~37,[5]],
		"/(public)/registro/profesional": [~38,[5]],
		"/(public)/registro/profesional/[inviteCode]": [~39,[5]],
		"/(public)/registro/profesional/[inviteCode]/completo": [~40,[5]],
		"/(public)/registro/verificar": [~41,[5]],
		"/(public)/reset-password": [~42,[4]],
		"/superadmin": [136,[16]],
		"/superadmin/links": [~137,[16]],
		"/superadmin/testimonials": [~138,[16]],
		"/superadmin/testimonials/new": [~139,[16]],
		"/(legal)/terminos-y-condiciones": [23,[3]],
		"/test/availability": [140,[17]],
		"/test/cal": [141,[17]],
		"/test/docuseal": [~142,[17]],
		"/test/dt": [143,[17]],
		"/test/encryption": [144,[17]],
		"/test/error": [~145,[17]],
		"/test/error/full": [~146,[17]],
		"/test/error/unexpected": [~147,[17]],
		"/test/invites": [148,[17]],
		"/test/invoice-number": [149,[17]],
		"/test/landing": [150,[17,18]],
		"/test/matching": [~151,[17]],
		"/test/navigation/a": [152,[17,19]],
		"/test/navigation/b": [153,[17,19]],
		"/test/number": [154,[17]],
		"/test/onboarding": [~155,[17]],
		"/test/onboarding/set": [~156,[17]],
		"/test/redirect/a": [157,[17]],
		"/test/redirect/b": [158,[17]],
		"/test/search": [~159,[17]],
		"/test/slots": [160,[17]],
		"/test/sms": [161,[17]],
		"/test/supabase": [~162,[17]],
		"/test/transactions": [~163,[17]],
		"/(public)/validate-invite": [43,[4]],
		"/[team]/(public)": [~44,[7]],
		"/[team]/admin": [~49,[6,9]],
		"/[team]/admin/analitica": [50,[6,9]],
		"/[team]/admin/calendario": [51,[6,9]],
		"/[team]/admin/configuracion": [~52,[6,9]],
		"/[team]/admin/configuracion/documentos": [~53,[6,9]],
		"/[team]/admin/configuracion/documentos/[id]": [~54,[6,9]],
		"/[team]/admin/configuracion/eholo": [~55,[6,9]],
		"/[team]/admin/configuracion/emails": [~56,[6,9]],
		"/[team]/admin/configuracion/emails/[emailType]": [~57,[6,9]],
		"/[team]/admin/configuracion/finance": [~58,[6,9]],
		"/[team]/admin/configuracion/finance/[legalEntityId]": [~59,[6,9]],
		"/[team]/admin/configuracion/import-v2": [~60,[6,9]],
		"/[team]/admin/configuracion/subscriptions": [~61,[6,9]],
		"/[team]/admin/configuracion/subscriptions/[id]/update-seats": [~62,[6,9]],
		"/[team]/admin/configuracion/templates-docuseal": [~63,[6,9]],
		"/[team]/admin/configuracion/templates-docuseal/new": [~65,[6,9]],
		"/[team]/admin/configuracion/templates-docuseal/[templateId]": [~64,[6,9]],
		"/[team]/admin/equipo": [66,[6,9,10]],
		"/[team]/admin/equipo/invitar": [~68,[6,9,10]],
		"/[team]/admin/equipo/[userId]": [~67,[6,9,10]],
		"/[team]/admin/finances": [~69,[6,9]],
		"/[team]/admin/invalid-subscription": [~70],
		"/[team]/admin/pacientes": [~71,[6,9]],
		"/[team]/admin/pacientes/[patientId]": [~72,[6,9]],
		"/[team]/admin/servicios": [~73,[6,9]],
		"/[team]/admin/sessions": [~74,[6,9]],
		"/[team]/admin/therapies": [~75,[6,9]],
		"/[team]/admin/therapies/new": [~76,[6,9]],
		"/[team]/admin/waitlist": [~77,[6,9]],
		"/[team]/(public)/create": [~45,[7]],
		"/[team]/profesional/(default)": [~78,[6,11]],
		"/[team]/profesional/configuracion": [~79,[6,11]],
		"/[team]/profesional/configuracion/encryption": [~80,[6,11]],
		"/[team]/profesional/configuracion/facturacion": [~81,[6,11]],
		"/[team]/profesional/configuracion/import": [82,[6,11]],
		"/[team]/profesional/configuracion/import/eholo": [~83,[6,11]],
		"/[team]/profesional/configuracion/import/google-calendar": [~84,[6,11]],
		"/[team]/profesional/configuracion/import/google-calendar/[calendarId]": [~85,[6,11]],
		"/[team]/profesional/configuracion/import/v2": [~86,[6,11]],
		"/[team]/profesional/dashboard": [~87,[6,11]],
		"/[team]/profesional/finances": [~88,[6,11]],
		"/[team]/profesional/finances/invoices/new": [~90,[6,11]],
		"/[team]/profesional/finances/invoices/[invoiceId]": [~89,[6,11]],
		"/[team]/profesional/invalid-license": [91],
		"/[team]/profesional/notificaciones": [92,[6,11]],
		"/[team]/profesional/patients": [~93,[6,11]],
		"/[team]/profesional/patients/archived": [~101,[6,11]],
		"/[team]/profesional/patients/new": [~102,[6,11]],
		"/[team]/profesional/patients/[patientId]": [~94,[6,11,12]],
		"/[team]/profesional/patients/[patientId]/documents": [~95,[6,11,12]],
		"/[team]/profesional/patients/[patientId]/documents/send": [~97,[6,11,12]],
		"/[team]/profesional/patients/[patientId]/documents/upload": [~98,[6,11,12]],
		"/[team]/profesional/patients/[patientId]/documents/[documentId]": [~96,[6,11,12]],
		"/[team]/profesional/patients/[patientId]/edit": [99,[6,11,12]],
		"/[team]/profesional/patients/[patientId]/events": [100,[6,11,12]],
		"/[team]/profesional/sessions": [~103,[6,11]],
		"/[team]/profesional/sessions/[sessionId]/edit": [~104,[6,11]],
		"/[team]/profesional/therapies": [~105,[6,11]],
		"/[team]/profesional/therapies/archived": [~115,[6,11]],
		"/[team]/profesional/therapies/new": [~116,[6,11]],
		"/[team]/profesional/therapies/[therapyId]": [~106,[6,11,13]],
		"/[team]/profesional/therapies/[therapyId]/documents": [107,[6,11,13]],
		"/[team]/profesional/therapies/[therapyId]/documents/send": [~109,[6,11,13]],
		"/[team]/profesional/therapies/[therapyId]/documents/upload": [~110,[6,11,13]],
		"/[team]/profesional/therapies/[therapyId]/documents/[documentId]": [~108,[6,11,13]],
		"/[team]/profesional/therapies/[therapyId]/events": [111,[6,11,13]],
		"/[team]/profesional/therapies/[therapyId]/notes/[noteId]": [~112,[6,11,13]],
		"/[team]/profesional/therapies/[therapyId]/settings": [~113,[6,11,13]],
		"/[team]/profesional/therapies/[therapyId]/work": [~114,[6,11,13]],
		"/[team]/(public)/p/[professional]": [~46,[7]],
		"/[team]/(public)/waitlist": [~47,[7,8]],
		"/[team]/(public)/waitlist/success": [48,[7,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';